import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { usePartners } from '../GraphQL/Queries'
import { Container, ContentHeader, Section } from '../components/Common'
import { MainHero } from '../components/Hero'
import { PartnersSection } from '../components/Partners'
import BookBanner from '../components/BookBanner/BookBanner'

const Partners = () => {
    const partners = usePartners()

    const hero = {
        image: partners.acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
        altText: partners.acf_hero.heroImage.altText,
        title: partners.acf_hero.bbTitle,
        description: partners.acf_hero.bbDescription,
    }
    const book = {
        image: partners.acf_bookCTA.image.sourceUrlSharp.childImageSharp.fluid,
        alt: partners.acf_bookCTA.image.altText,
        title: partners.acf_bookCTA.title,
    }
    const partnersContent = {
        title: partners.acf_partnersContent.mainTitle,
        description: partners.acf_partnersContent.mainDescription,
    }

    return (
        <Layout pageClass="partners-page">
            <SEO title={partners.title} />

            <MainHero
                alt={hero.altText}
                image={hero.image}
                title={hero.title}
                description={hero.description}
            />

            <ContentHeader
                title={partnersContent.title}
                description={partnersContent.description}
            />

            <Section>
                <Container>
                    <PartnersSection large />
                </Container>
            </Section>

            <BookBanner image={book.image} alt={book.alt} title={book.title} />
        </Layout>
    )
}

export default Partners
