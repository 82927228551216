import React from 'react'
import PropTypes from 'prop-types'
import PartnerLogo from './PartnersLogo'
import { useAllPartners } from '../../GraphQL/Queries'

const PartnersSection = ({ large }) => {
    const partners = useAllPartners()
    return (
        <div className="partners">
            {partners &&
                partners.edges.map(partner => (
                    <PartnerLogo
                        alt={partner.node.acf_partners.logo.image.altText}
                        image={partner.node.acf_partners.logo.image.sourceUrl}
                        large={large ? large : false}
                    />
                ))}
        </div>
    )
}

PartnersSection.propTypes = {}

export { PartnersSection }
