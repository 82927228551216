import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PartnersLogo = ({ image, alt, large }) => {
    return (
        <div
            className={`partners__logo-container ${
                large ? 'partners__logo-container--xl' : ''
            }`}
        >
            <img src={image} alt={alt} className="partners__logo" />
        </div>
    )
}

PartnersLogo.propTypes = {}

export default PartnersLogo
